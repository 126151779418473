.App {
  /* text-align: center; */
  width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 2000;
  background: lightgray;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  left: 0px;
  right: 0px;
}

.loader svg {
  top: 42%;
  bottom: 50%;
  left: 46%;
  right: 50%;
  position: absolute;
  z-index: 2000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}