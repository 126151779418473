:root {
    --green: #88C44B;
    --gray: #707070;
    --segoeFont: 'Segoe UI', sans-serif;
}

body {
    overflow-y: auto;
    overflow-x: hidden;
}

.accordion-button::after {
    background-image: url('../images/help/plus-solid.svg');
}

.accordion-button:not(.collapsed)::after {
    background-image: url('../images/help/minus-solid.svg');
}

.baetho-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
}

.ocean {
    position: relative;

}

/* .ocean {
    height: 80%;
    width: 100%;
    position: absolute;
    top: 300px;

    left: 0;
    background: #fff;
} */


.bg-img {
    height: 100%;
    background: url('../images/help/BG.png'), rgb(255, 255, 255);
    position: relative;
    margin-top: -5px;
    padding-bottom: 50px;
}

.wave {
    background: url('../images/help/wave.svg');
    position: relative;
    top: 0px;
    width: 100vw;
    height: 200px;
    /* -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0); */
}


.wave-1 {
    /* position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url('../images/wave-2.svg') repeat-x;
    background-size: 1920px 100px;
    background-repeat: no-repeat;
    transform: scale(1.5);
    animation: animate 30s linear infinite; */

    background: url('../images/help/wave-2.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 90px;
    width: 200vw;
    height: 300px;
    -webkit-animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    transform: scale(0.7);
    z-index: 1;
}

/* .wave-1 {
    position: absolute;
    top: 90px;
    left: 0;
    height: 300px;
    width: 200vw;
    background: url(../images/help/wave-2.svg);
    background-size: 2000px 100px;
    background-repeat: no-repeat;
    -webkit-animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
} */


@keyframes wave {
    0% {
        margin-left: -550px;
    }

    100% {
        margin-left: -1400px;
    }
}

/* @keyframes swell {

    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }

    50% {
        transform: translate3d(0, 5px, 0);
    }
} */

/* @keyframes animate {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -1920px;
    }
} */



/* @-webkit-keyframes wave {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1600px;
    }
}

@keyframes wave {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1600px;
    }
} */
/* @keyframes animate {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1920px;
    }
} */

/* @keyframes wave-1 {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1600px;
    }
} */

/* @-webkit-keyframes swell {

    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }

    50% {
        transform: translate3d(0, 5px, 0);
    }
}

@keyframes swell {

    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }

    50% {
        transform: translate3d(0, 5px, 0);
    }
} */

.docs-logo-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.baetho-logo {
    margin-left: 20px;
    margin-top: 30px;
}



/* ------------Left Panel Start------------- */
.left-container {
    overflow: auto;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.left-panel {
    text-align: left;
    padding-left: 30px;
    margin-top: 30px;
    height: 650px;
    overflow: auto;
    position: relative;
}

.docs-back-btn {
    font-size: 18px;
    position: absolute;
    top: -20px;
    left: 20px;
    z-index: 9999999;
    color: #fff;
    background-color: #88C44B;
    padding: 2px 15px;
    border-radius: 5px;
}

.docs-back-btn:hover {
    color: #fff;
}

.docs-back-btn .fa-chevron-left {
    margin-right: 10px;
    color: #fff;
}

.left-panel h2 {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    font-family: var(--segoeFont);
    color: var(--gray);
}

.left-panel span {
    font-size: 20px;
    font-family: var(--segoeFont);
    margin-left: 10px;
}

.left-panel .text-white {
    color: #ffffff;
}

.left-panel .text-gray {
    color: var(--gray);
    font-weight: 600;
}

li.docs-nav-item {
    width: 200px;
    height: auto;
    box-sizing: border-box;
    padding: 0px;
    cursor: pointer;
}

.docs-disable-link {
    pointer-events: none;
}


.selected {
    /* background: var(--green); */
    /* outline: 2px solid var(--green); */
    width: 100%;
    background: url('../images/help/listBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.de-selected:hover {

    /* outline: 2px solid var(--green);
    border-radius: 0 20px 0 20px; */
    background: url('../images/help/Rectangle.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.nav-link.docs {
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.left-panel::-webkit-scrollbar {
    width: 5px;
}

.left-panel::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.left-panel::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: darkgray;
}

/* ------------Left Panel End------------- */
/* --------------Main content Start------------ */
.content-main {
    width: 95%;
    box-shadow: 2px 2px 10px #00000029;
    border-radius: 31px;
    opacity: 1;
    text-align: left;
    padding: 31px 60px;
    background-color: #fff;
    margin-top: 30px;
    height: 665px;
    overflow: auto;
    position: relative;
    color: var(--gray);

    /* overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; */
}

.content-main::-webkit-scrollbar {
    width: 5px;
}

.content-main::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.content-main::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: darkgray;
}

/* .content-main::-webkit-scrollbar {
    display: none;
} */

.content-main::-webkit-scrollbar {
    scrollbar-width: 2px
}

.pr {
    padding-right: 80px;
}

.content-main .title {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--gray);
}

.sub-title {
    font-size: 28px;
    font-weight: bold;
    color: var(--gray);
    font-family: var(--segoeFont);
}

.sub-title-2 {
    font-size: 23px;
    font-weight: 700;
    color: var(--gray);
    font-family: var(--segoeFont);
}


.property-list li {
    line-height: 2.5;
    font-size: 18px;
    font-weight: 400;
    color: var(--gray);
    font-family: var(--segoeFont);
}

.single-list {
    margin-bottom: 20px;
}

.content-main p {
    font-size: 18px;
    font-family: var(--segoeFont);
}

.property {
    font-weight: 700;
    color: var(--gray);
    font-size: 18px;
}


.trigger {
    font-size: 18px;
    font-weight: 700;
}

.parameter {
    margin-left: 30px;
    font-size: 18px;
}

.section {
    margin-top: 30px;
    margin-bottom: 30px;
}

.accordion-button:not(.collapsed) {
    color: #88c44bcc;
    background-color: #88c44bcc;
    box-shadow: inset 0 calc(-1 * #88c44bcc) 0 #88c44bcc;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #88c44bcc;
    outline: 0;
    box-shadow: inset 0 0 0 0.25rem #88c44bcc;
}

/* --------------Main content End------------ */
/*---------- Animated icons-------- */
.icon {
    position: absolute;
    z-index: 0;
    width: 60px;
}

.icon.icon-1 {
    right: 50px;
    bottom: 300px;
    animation: myanimation 5s ease-out infinite;
}

.icon.icon-2 {
    right: 50px;
    bottom: 200px;
    animation: myanimation 4s ease-out infinite;
}

.icon.icon-3 {
    right: 50px;
    bottom: 100px;
    animation: myanimation 3s ease-out infinite;
}

.icon.icon-4 {
    right: 250px;
    bottom: -5%;
    animation: myanimation 2s ease-out infinite;
}

.icon.icon-5 {
    right: 350px;
    bottom: -5%;
    animation: myanimation 4s ease-out infinite;
}

.icon.icon-6 {
    right: 150px;
    bottom: -5%;
    animation: myanimation 3s ease-out infinite;
}

.icon.icon-7 {
    left: 290px;
    bottom: 90px;
    animation: myanimation 3s ease-out infinite;
}

.icon.icon-8 {
    left: 0px;
    top: 0%;
    width: 34px;
}

.icon.icon-9 {
    right: 60px;
    top: 0%;
    width: 44px;
}

.icon.icon-10 {
    left: 0px;
    bottom: -5%;
    width: 44px;
}

.icon.icon-11 {
    right: 60px;
    bottom: -5%;
    width: 34px;
}

@keyframes myanimation {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -o-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3)
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.shake {
    animation: tilt-shaking 1s infinite;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

/* ---hide mobile menu for desktop view--- */
.backdrop {
    display: none;
}

.backdrop-inactive {
    display: none;
}

.menu-icon {
    display: none;
}

/* -----------------Media query------------------ */

@media (max-width:991px) {

    /* --------mobile menu------- */
    .left-panel {
        margin-top: 0;
    }

    .content-main {
        height: 100%;
        margin: 50px auto 0 auto;
        padding: 50px 20px;
    }

    .pr {
        padding-right: 10px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        position: fixed;
        top: 0px;
        left: -100%;
        opacity: 1;
        /* transition: all 0.5s ease; */
    }

    .nav-menu.mobile-menu {
        width: 300px;
        height: 100%;
        /* align-items: flex-start; */
        position: fixed;
        background: #fff;
        padding-top: 30px;
        padding-left: 15px;
        top: 0;
        left: 0;
        opacity: 1;
        transition: all 0.3s ease;
        z-index: 10;
    }

    .menu-icon {
        display: block;
        position: absolute;
        right: 20px;
        top: 40px;
        color: #fff;
        cursor: pointer;
    }

    .backdrop {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.35);
        z-index: 1;
    }

    /* ---- Logo ----- */
    .docs-logo-wrap {
        width: 150px;
        /* margin-left: 20px;
        margin-top: 20px; */
    }

    .docs-logo-wrap img {
        width: 100%;
    }

    .docs-logo-box {
        height: 80px;
    }

    .docs-logo-box a {
        width: 150px;
        height: 80px;
    }

    /* -----Animated icons----- */
    .icon.icon-1 {
        right: 10px;
        bottom: 300px;
        width: 44px;
    }

    .icon.icon-2 {
        right: 10px;
        bottom: 200px;
        width: 44px;
    }

    .icon.icon-3 {
        right: 10px;
        bottom: 100px;
        width: 44px;
    }

    .icon.icon-4 {
        right: 150px;
        bottom: -4%;
        width: 44px;
    }

    .icon.icon-5 {
        right: 250px;
        bottom: -4%;
        width: 44px;
    }

    .icon.icon-6 {
        right: 100px;
        bottom: -4%;
        width: 44px;
    }

    .icon.icon-8 {
        left: 10px;
        top: 0%;
        width: 44px;
    }

    .icon.icon-9 {
        right: 7px;
        top: 0%;
        width: 44px;
    }

    .icon.icon-10 {
        left: 10px;
        bottom: -4%;
        width: 44px;
    }

    .icon.icon-11 {
        right: 10px;
        bottom: -4%;
        width: 34px;
    }

    .wave-1 {
        background: url('../images/help/wave-2.svg');
        background-repeat: no-repeat;
        position: absolute;
        top: 100px;
        width: 200vw;
        height: 200px;
        -webkit-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
        animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
        transform: translate3d(0, 0, 0);
        transform: scale(0.7);
        z-index: 1;
    }

    @keyframes wave {
        0% {
            margin-left: -100px;
        }

        100% {
            margin-left: -200px;
        }
    }

    @keyframes swell {

        0%,
        100% {
            transform: translate3d(0, -25px, 0);
        }

        50% {
            transform: translate3d(0, 5px, 0);
        }
    }
}

/* -----------------------------new design---------------------------- */
.docs-wrapper {
    height: 100%;
}

/* .myVideo {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
} */



/* .pattern:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 215px;
    background: url(../images/help/wave.svg);
    background-size: cover;
    background-repeat: no-repeat;
} */

/* .white-bg {
    width: 100%;
    height: 100%;
    background: url(../images/help/BG.png), #fff;
} */

.docs-logo-wrap {
    position: absolute;
    top: 30px;
    left: 10px;
}

.video-bg {
    width: 100ww;
    height: 30vh;
    overflow: hidden;
    z-index: -1;
}

.video-bg::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 200px;
    bottom: 69vh;
    left: 0;
    background-image: url(../images/help/wave.svg);
    z-index: 0;
}

.content-container {
    width: 100%;
    height: 70vh;
    background-image: url(../images/help/BG.png);
}

.icon-wrap {
    position: relative;
}

.content-main h2 {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    font-family: var(--segoeFont);
    color: var(--gray);
}

.content-main span {
    font-size: 20px;
    font-family: var(--segoeFont);
    margin-left: 10px;
}