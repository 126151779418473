:root {
    --red: #fc766aa9;
    --green: #A9DABC;
    --yellow: #FFDE39;
    --blue: #837DFF;
    --blueDark: #605bc2;
    --poppinsFont: 'Poppins', sans-serif;
    --fontColor: '#212529';
    --backgroundImage: url('../images/bg.jpg');
    --btnShadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    --offcanvas-width: 270px;
    --topNavBarHeight: 56px;
    --layoutShadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    --blueShadow: 0px 4px 12px rgb(131, 125, 255, 0.1);
    --dashboard-background: #F6F8FC;
    --materialShadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
}

/* ----------Text Styles Start------- */
.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer;
}

a {
    color: var(--blue) !important;
}

/* ----------Text Styles End------- */
.opacity-7 {
    opacity: 0.7;
}

.bg-blue {
    background-color: var(--blue);
}

.bg-white {
    background-color: #ffffff !important;
}

.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}

.bg-blue:hover,
.bg-blue:focus {
    background-color: var(--blue) !important;
}

.text-white {
    color: #ffffff;
}

.text-yellow {
    color: var(--yellow);
}

.color-blue {
    color: var(--blue);
}

.text-left {
    text-align: left;
}

.layout-shadow {
    box-shadow: var(--layoutShadow);
}

.width-200-px {
    min-width: 200px;
}

.box-shadows {
    box-shadow: 0px 0px 6px #00000029;
    /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
}

.card-header {
    background-color: #ffffff !important;
}

.card-footer {
    background-color: #ffffff !important;
}

.boxShadow {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border-radius: 10px;
}

.filter-btn {
    box-shadow: 0 3px 6px rgba(39, 45, 59, .2);
    background-color: #fff;
    border: none;
    transition: all .2s;
    text-transform: uppercase;
}

/*-------------------- Timeline start------------------ */
/* .vertical-timeline-element-subtitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 0 !important;
    color: var(--fontColor) !important;
} */

/* .vertical-timeline-element-text {
    margin-top: 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
} */

.vertical-timeline-element-date {
    display: none !important;
}

.vertical-timeline-element-content {
    box-shadow: none !important;
    border-radius: 8px !important;
    padding: 0 !important;
}

.vertical-timeline-element {
    margin-bottom: 0 !important;
}

.timeline-status-yellow {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
    color: #ffbf00;
    text-align: left;
}

.timeline-status-red {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
    color: red;
    text-align: left;
}

.timeline-status-green {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
    color: #1cc88a;
    text-align: left;
    font-weight: 600;
}

.timeline-details-btn:hover {
    color: var(--fontColor) !important;
    background-color: var(--dashboard-background);
}

.fa-blue {
    color: #837DFF;
}

.fa-gray {
    color: gray;
}

/*-------------------- Timeline end------------------ */

/* ----------Input form style start---------- */

.input-style {
    padding: 10px 14px;
    color: #000;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.input-style:hover {
    background-color: var(--dashboard-background);
}

label {
    margin-bottom: 8px;
    font-weight: 500;
}

.input-style:focus {
    box-shadow: 0 0 0 0.10rem rgba(131, 125, 255, 0.8);
}

.input-info {
    font-size: 12px;
    margin-left: 10px;
}

/* ----------Input form style end---------- */
/* .card {
    border: none;
    border-left: 4px solid var(--blue)
} */

.btn-yellow {
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #FFDE39;
    color: #000;
    cursor: pointer;
    padding: 5px 100px;
    box-shadow: var(--btnShadow);
}

.btn-blue {
    background-color: var(--blue);
}

.btn-blue:hover,
.btn-blue:active {
    background-color: var(--blue) !important;
}


.mainBG {
    width: 100vw;
    height: 100vh;
    background: url('../images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.form {
    background-color: #837DFF;
    width: 370px;
    min-height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    padding: 20px 20px;
    font-size: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.title {
    font-size: 30px;
    margin-bottom: 20px;
    color: #ffffff;
}

.input-label {
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 5px;
}

.input-field {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.submit-btn {
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #FFDE39;
    color: #000;
    cursor: pointer;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
    box-shadow: var(--materialShadow);
    transition: background-color 0.5s ease;
}

.submit-btn:hover {
    background-color: #ebcc33;
}

.verify-text {
    color: #ffffff;
    text-align: center;
    margin-top: 120px;
    vertical-align: middle;
    font-size: 25px;
}

.main-container {
    width: 100vw;
    height: 100vh;
}

.lessons-container {
    width: 100%;
    height: 100%;
}

/* Popup styles */

.popup-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}

/* -----------------Login Start------------------- */
.login-form-container {
    padding-top: 100px;
}

.login-form {
    width: 400px;
    min-height: 400px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--blue);
    padding: 30px;
    border-radius: 10px;
}

.login-form h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
}

.login-form .login-label {
    font-size: 16px;
    color: #fff;
    margin: 0px;
}

.login-form .login-type-info {
    color: #C7D2FE !important;
    cursor: pointer;
    display: inline-block;
}

.login-form .login-type-info:hover {
    color: #fff !important;
    cursor: pointer;
}

.login-form .input-phone,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   
} */
/* .login-label {
    text-align: left;
    width: 100%;
    color: #ffffff;
    margin-bottom: 2px;
    margin-top: 5px;
    margin-left: 5px;
}

.links {
    display: inline-block;
    margin-top: 5px;
    color: #ffffff;
}

.links a {
    color: #ffffff;
    margin-right: 30px;
}

.bg-gray {
    background-color: #F2F2F2 !important;
    height: 100vh;
}

.unsubscribe-bg-blue {
    background: url('../images/bg.jpg');
    height: 100vh;
}

.unsubscribe-login-wrap {
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}

.unsubscribe-bg-blue input,
.unsubscribe-bg-blue input:focus {
    background-color: #f7f7f7;
} */

/* -----------------Login End------------------- */
/* -----------------Upload Lesson Start-------------------- */

.upload-lesson-container {
    background-image: url('../images/bg.jpg');
    width: 100%;
    height: 100%;
}

.form-container {
    background-color: #ffffff;
    width: 70%;
    margin: 0 auto;
    min-height: 400px;
    color: #ffffff;
    padding: 10px 40px;
    font-size: 16px;
    /* box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%); */
}

.input-wrap {
    margin-top: 30px;
}

.upload-lesson-label {
    margin-right: 40px;
    text-align: left;
    color: #000;
    width: 100%;
    margin-bottom: 10px;
}

.input-wrap {
    text-align: left;
}

.file-input {
    width: 280px;
    border: 1px solid #ccc;
    color: #000;
    border-radius: 5px;
}

.form-input {
    height: 45px;
    width: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    padding: 5px 10px;
}

.desc-area {
    outline: none;
    border: 1px solid #ccc;
    padding: 2px 5px;
    width: 500px;
    border-radius: 5px;
}

.form-input:hover,
.file-input:hover,
.desc-area:hover {
    background-color: #F2F7FB;
}

/* createable input  */
.css-13cymwt-control:hover {
    background-color: #F2F7FB !important;
}

.creatable {
    color: #000;
    width: 100%;
}

.upload-lesson-label span {
    font-size: 12px;
}

.submit-lesson-btn {
    width: 300px;
}


.btn-logout {
    position: absolute;
    right: 200px;
}

.left-align {
    text-align: left;
}



/* -----------------Upload Lesson End-------------------- */


/* -----------------Admin Dashboard Start-------------------- */
/*---------Left panel start-----------*/
.dashboard-bg {
    background-color: #F6F8FC;
    min-height: 100%;
    /* background-color: #fff; */
}

.active-menu {
    background-color: var(--yellow);
}

.pos-rel {
    position: relative;
}

.left-panel-container {
    width: 15%;
    height: 100vh;
    background-color: var(--blue);
    position: fixed;
    /* box-shadow: 0 3px 6px #00000029; */
}

.leftpanel-logo {
    width: 50%;
    height: auto;
    margin: auto;
}

.leftpanel-logo img {
    width: 100%;
    margin: 30px auto 20px auto;
}

.left-panel-menubar {
    width: 100%;
    color: gray;
    position: absolute;
    top: 30%;
    left: 0;
}

.left-panel-container .nav-item {
    padding-left: 20px;
    text-align: left;
}

.left-panel-container .nav-link {
    width: 100%;
}

.leftpanel-icons-white {
    color: #fff;
}

.leftpanel-icons-black {
    color: #000;
}

.logout-btn-wrap {
    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;
}

.logout-btn-wrap button {
    width: 100%;
    background-color: #FFDE39;
    padding: 5px 10px;
    color: black;
    border: none;
    outline: none;
}

/*---------Left panel end-----------*/
/*---------Right panel start-----------*/
.right-panel-container {
    /* padding: 0 5px 0 25px;
    width: 100%; */
    /* width: 100%;
    height: 100vh; */
    min-height: 700px;
}

.shadows {
    /* box-shadow: 0 3px 10px #00000029; */
    box-shadow: var(--blueShadow)
}

.data-container-wrapper {
    background: #fff;
    border: 7px solid #fff;
    min-height: 600px;
    max-height: 760px;
    /* box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px; */
    /* box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important; */
    /* box-shadow: 0 0 5px 0 rgba(43, 43, 43, .1),
        0 11px 6px -7px rgba(43, 43, 43, .1); */
    overflow: auto;
    border-radius: 5px;
    /* padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px; */
    margin-bottom: 24px;
}

.data-container-wrapper::-webkit-scrollbar {
    width: 5px;
}

.data-container-wrapper::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.data-container-wrapper::-webkit-scrollbar-thumb {
    border-style: solid;
    border-color: transparent;
    border-width: 4px;
    background-color: darkgray;
    border-radius: 8px;
    box-shadow: none;
}

.bg-gray {
    background-color: #F7F8FA;
}

.HeaderSection {
    background-color: #ffffff;
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 5px 30px;
    font-weight: 700;
    color: black;
    border-radius: 5px;
    margin-bottom: 20px;
}

.section-name {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0px;
    text-align: left;
    color: var(--fontColor) !important;
}

.profile-name {
    margin-bottom: 0;
    font-size: 16px;
    color: #000;
}

.admin-logout {
    width: 100px;
    font-size: 16px;
    background-color: var(--blue);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: var(--btnShadow);
    color: #ffffff;
    border: none;
    outline: none;
    margin-left: 30px;
}

.approve-btn {
    width: 100px;
    background-color: var(--blue);
    padding: 5px 5px;
    color: #ffffff;
    text-align: center;
    box-shadow: var(--btnShadow);
    border-radius: 5px;
    border: none;
    outline: none;
}

.reject-btn {
    width: 100px;
    background-color: #FC766A;
    padding: 5px 5px;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: var(--btnShadow);
    border-radius: 5px;
    border: none;
    outline: none;
}

/* .sc-hLBbgP div:first-child {
    height: 100%;
} */

.reject-info {
    width: 100%;
    min-height: 200px;
    padding: 10px;
    border: 1px solid gray;
    outline: none;
    margin-bottom: 20px;
    border-radius: 5px;
}

.fa-download {
    margin-bottom: 5px;
    margin-left: 5px;
    color: var(--blue);
    cursor: pointer;
}

.thumbnails img {
    width: auto;
    height: 50px;
    cursor: pointer;
}

.popup-img {
    width: auto;
    height: 500px;
}

/* .image-wrap {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-bottom: 10px;
} */

.image-wrap {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    overflow: hidden;
    user-select: none;
}

.image-wrap img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
    cursor: pointer;
}


/* .view-image {
    cursor: pointer;
    width: 60px;
    height: 60px;
} */

.play-btn {
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    position: relative;
    color: #fff;
    background-color: #FFB64D;
    border-radius: 5px;
    padding: 5px 10px;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    min-width: 64px;
    box-shadow: var(--btnShadow);
}

.modal-1000w {
    width: 1000px;
    max-width: 1000px;
    height: 700px;
}

.transparent-bg {
    background: transparent;
    border: none;
}

.modal-90w {
    width: 1366px;
    max-width: 1300px;
    padding: 0;
    min-height: 800px;
}

.play-lesson-btn {

    /* color: var(--blue);
    font-weight: 500;
    cursor: pointer; */

    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    position: relative;
    color: #fff;
    background-color: #FFB64D;
    border-radius: 5px;
    padding: 5px 10px;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    min-width: 64px;
    box-shadow: var(--btnShadow);
}

/* .data-box {
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
} */

.data-value {
    font-weight: 600;
}

.data-text {
    color: var(--blue);
}

.back-btn {
    color: #fff;
    cursor: pointer;
    background-color: var(--blue);
    padding: 5px 15px;
    border-radius: 5px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.data-container-wrapper .fa-chevron-left {
    margin-right: 5px;
}

.data-card {
    height: 150px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 3px 6px #00000029
}

.chart-card {
    min-height: 340px;

    /* background-color: #fff;
    margin-top: 20px;
    box-shadow: 0 3px 6px #00000029;
    overflow-x: auto; */
}

.right-border {
    border-right: 1px solid gray;
}

.loading-text {
    font-size: 18px;
    font-weight: 600;
}



.player-wrap {
    /* box-shadow: 0 3px 6px #00000029;
    background-color: #fff; */
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    min-height: 720px;
}

.player-iframe {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    outline: 10px solid #bbb;
    /* border-radius: 8px; */
}

/* .modal-header .btn-close {
    color: #fff;
} */

/*---------Right panel end-----------*/
/* -----------------Admin Dashboard End-------------------- */


/* -------------Teacher Dashboard Start--------------- */
.pending-status {
    background-color: #FFB64D;
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
}

.approved-status {
    background-color: #2ed8b6;
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
}

.rejected-status {
    background-color: #ff5370;
    padding: 2px 5px;
    color: #fff;
    border-radius: 5px;
}

.badge-inverse-primary {
    border: 1px solid;
    border-color: var(--blue);
    color: var(--blue);

}

.tags {
    /* display: inline-block; */
    border-radius: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    /* vertical-align: baseline; */

}

/* ---hide mobile menu for desktop view--- */
.admin-panel-backdrop {
    display: none;
}

.backdrop-inactive {
    display: none;
}

.mobile-menu-icon {
    display: none;
}

.content-box {
    width: 100%;
    min-height: 100px;
    padding: 25px 25px 25px 25px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    /* border: 2px solid var(--blue); */
}

.content-box-subtitle {
    font-size: 14px;
    color: #111927;
}

.chat-box-wrap {
    width: 100%;
    min-height: 600px;
    max-height: 700px;
    padding: 35px;
    overflow: auto;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.msg-wrap {
    padding: 10px;
    width: 100%;
    border-radius: 10px;
}

.chat-text-area {
    height: 100px !important;
}

.profile-card {
    min-height: 100px;
    background-color: #fff;
    font-size: 18px;
}

.upload-lesson-form {
    background-color: #fff;
    padding: 40px;
    box-shadow: var(--blueShadow);
    border-radius: 10px;
}

.upload-lesson-form input:hover {
    background-color: var(--dashboard-background);
}

.upload-lesson-form textarea:hover {
    background-color: var(--dashboard-background);
}


.upload-lesson-form .form-floating>label {
    left: 10px !important;
}


.thumbnail-update-input {
    display: none;
}

/* .tags {
    display: inline-block;
    border: 1px solid var(--blue);
    margin-right: 5px;
    border-radius: 10px;
    padding: 3px 7px;
    font-size: 14px;

} */

/* -------------Teacher Dashboard End--------------- */

/* -------------Marketing Dashboard Start--------------- */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

/* -------------Marketing Dashboard End--------------- */
/* -------------Homepage start------------- */

.navbar-blue {
    background-color: var(--blue);
    padding: 5px 50px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
        rgb(0 0 0 / 14%) 0px 4px 5px 0px,
        rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}

.navbar-login-btn {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    border: 2px solid #fff;
    padding: 2px 15px;
    border-radius: 5px;
}

.navbar-login-btn:hover {
    color: #fff;
}

.logo-box {
    width: 173px;
}

.logo-box img {
    width: 100%;
}

.download-app-img {
    width: 100%;
}

.main-bg {
    width: 100%;
    min-height: 100%;
    background: url('../images/bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    overflow-x: hidden;
}

.home-title h1 {
    font-size: 35px;
    font-weight: 600;
    color: var(--blue);
    text-transform: uppercase;
}

.modal-bg {
    background: url('../images/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

/* -Lesson Card Start- */

.card-primary {
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.card-secondary {
    border-radius: 10px;
}

.image-card-wrap {
    width: 414px;
    height: 350px;
    margin-right: 10px;
}

.card-image-wrap {
    width: 100%;
    height: 100%;
}

.card-secondary img {
    /* width: 100%;
    border-radius: 10px 10px 0 0; */
    height: 200px;
    display: block;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-position: center;
    background-position: center;
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.card-title {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card-text {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

/* -Lesson Card End- */

/* -Lesson Modal Start-- */
.header-modal {
    background-color: #837DFF;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
        rgb(0 0 0 / 14%) 0px 4px 5px 0px,
        rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    border-bottom: none;
    border-bottom: 0;
    padding: 5px 50px;
}

.modal-header .btn-close {
    color: #fff;
    opacity: 1;
}

/* --Lesson Modal End-- */

/* ---------Pricing start------------------- */
.pricing-section {
    height: 75%vh;
    padding-top: 50px;
    padding-bottom: 30px;
}

.pricing-title {
    margin-bottom: 50px;
    text-align: center;
    color: var(--blue);
}

.pricing-title h1 {
    font-size: 35px;
    font-weight: 600;
}

.pricing-card {
    color: #111;
    background-color: #fff;
    padding: 40px 20px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid #837dff7c;
    box-shadow: var(--materialShadow);
}


.highlight {
    color: #111;
    background-color: #fff;
    padding: 40px 20px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 2px solid var(--blue);
    box-shadow: var(--materialShadow);
    position: relative;
}

.pricing-badge {
    position: absolute;
    top: -15px;
    left: 50%;
    background-color: #f8e792;
    margin-bottom: 0px;
    transform: translate(-50%, 0);
    margin: 0 auto;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 500;
}

.pricing-badge p {
    margin-bottom: 0;
}

.btn-pricing {
    background-color: var(--blue);
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 100%;
    transition: background-color .5s ease;
}

.btn-pricing:hover {
    background-color: #736edf;
}

.pricing-card .strike-through {
    text-decoration: line-through;
}

.pricing-card ul {
    list-style-type: circle;
}

.pricing-card ul li {
    margin-bottom: 10px;
}

.save-price {
    background-color: var(--blue);
    padding: 2px 5px;
    border-radius: 50px;
    border: none;
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
    display: inline-block;
}

.pricing-modal-container {
    height: 200px;
}

.pricing-modal-title h4 {
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
}

.pricing-download-app {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.pricing-modal-bg {
    background-image: url('../images/blue-bg.png');
    background-size: cover;
    background-position: center;
}

.benefits-heading {
    font-size: 18px;
    font-weight: 600;
}

.benefits-container ul {
    list-style-type: circle;
}

.pricing-main {
    background-color: #f8f8ff;
}

.premium-member-text {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
}

.feedback-section {
    width: 100%;
    min-height: 760px;
}

.pricing-feedback-container {
    max-width: 500px;
    min-height: 400px;
    background-color: #fff;
    padding: 40px;
    box-shadow: var(--materialShadow);
    margin: 0 auto 0 auto;
    margin-bottom: 50px;
}

.feedback-title {
    margin-bottom: 30px;
}

.pricing-feedback-container .feedback-title .feedback-text {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.pricing-feedback-container textarea {
    width: 100%;
    height: 200px;
}

/* ---------Pricing ends------------------- */

/* -------------Profile start------------ */
.profile-bg {
    background-color: #f8f8ff;
}

.profile-container {
    padding: 50px;
    box-shadow: var(--materialShadow)
}

.profile-title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
}

.profile-details {
    padding: 20px;
    border: 2px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px;
}

.text-blue {
    color: var(--blue);
}

/* -------------Profile end------------ */

/*-- Responsive player iframe start-- */

.modal-player-container {
    width: 100%;
    /* aspect-ratio: 16 / 9.4; */
    /* height: 740px; */
    /* padding-top: calc(100% / 1.777); */
    background: #fff 0% 0% no-repeat padding-box;
    /* border-radius: 26px; */
    padding-top: 56.25%;
    position: relative;
}

.modal-player-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.lesson-modal-back-btn {
    padding: 2px 5px;
    background-color: transparent;
    border: none;

}

/*- Responsive player iframe end- */
/* ---------------Homepage ends----------------- */

/* --------------------Chats start------------------------- */
.chats-window {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 600px;
    width: 700px;
    background-color: #fff;
    box-shadow: var(--blueShadow);
    border-radius: 10px;
    padding: 20px;
}


.chat-wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 600px;
    width: 700px;
}

.chat-body {
    overflow-y: auto;
}

.chat-textarea {
    max-height: 100px;
    min-height: 50px;
    outline: none;
    border: none;
    width: 100%;
    padding: 10px;
    background-color: #eee;
}

.chat-textarea::-webkit-scrollbar,
.chat-body::-webkit-scrollbar {
    width: 5px;
}

.chat-textarea::-webkit-scrollbar-track,
.chat-body::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.chat-textarea::-webkit-scrollbar-thumb,
.chat-body::-webkit-scrollbar-thumb {
    border-style: solid;
    border-color: transparent;
    border-width: 4px;
    background-color: darkgray;
    border-radius: 8px;
    box-shadow: none;
}

.message-wrap {
    border-radius: -20px 18px 18px 18px;
    max-width: 70% !important;
    text-align: left;
}

.user-name {
    font-size: 14px !important;
}

.message-body {
    padding: 10px;
}

.my-msg {
    background-color: var(--blue);
    color: #fff;
}

.my-msg::after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    right: -10px;
    left: auto;
    top: 0px;
    bottom: auto;
    border: 10px solid;
    border-color: var(--blue) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.my-msg::before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    right: -12px;
    left: auto;
    top: -1px;
    bottom: auto;
    border: 11px solid;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.other-msg::after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 10px solid;
    border-color: #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);

}

.other-msg::before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -12px;
    right: auto;
    top: -1px;
    bottom: auto;
    border: 11px solid;
    border-color: rgba(0, 0, 0, 0.175) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.fa-user-circle {
    color: var(--blue);
    margin-right: 5px;
    font-size: 25px;
}

.chat-send-btn {
    text-align: right;
}

/* --------------------Chats end------------------------- */


/* ----------------------WhatsApp Chat Start---------------- */



/* ----------------------WhatsApp Chat End---------------- */
/* FOOTER  */
.footer-copyright {
    font-size: 14px;
    text-align: center;
}

/* -----new code--- */
.sidebar-nav {
    width: var(--offcanvas-width) !important;
    /* height: 100vh;
    background-color: red !important; */
}

.offcanvas .btn-close {
    color: #fff !important;
    display: none;
}

/* -----new code---- */

/* -----------------------Notification Start-------------------------- */
.checkbox-group {
    display: flex;
    font-size: 20px;
    justify-content: space-around;
}

input[type=checkbox] {
    margin: 4px 0 0;
    line-height: normal;
    width: 20px;
    height: 20px;

}

/* Unsubscribe email page */
.unsubscribe-wrap {
    padding: 50px 50px 50px 50px;
    background-color: rgba(128, 128, 128, 0.192);
    margin-top: 50px;
}


/* ----------------------Notification End--------------------------- */
/* ---------------------Media query---------------------- */
@media only screen and (max-width:1199px) {
    .mobile-container {
        width: 100%;
        position: fixed;
        top: 0px;
        left: -100%;
        opacity: 1;
    }

    .mobile-left-panel {
        top: 0;
        left: 0;
        width: 250px;
        z-index: 99;
        transition: all 0.3s ease;
    }

    .mobile-menu-icon .fa-bars {
        position: absolute;
        top: 50px;
        left: 30px;
        cursor: pointer;
    }

    .mobile-menu-icon .fa-times {
        position: absolute;
        top: 50px;
        left: 30px;
    }

    .admin-panel-backdrop {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.35);
        z-index: 9;
    }

    .mobile-menu-icon {
        display: block;
    }

    .player-back-btn {
        margin-left: 50px;
    }
}

@media only screen and (max-width: 1224px) {
    .form-container {
        width: 90%;
    }

    .file-input {
        width: 100%;
    }

    .form-input {
        width: 100%;
    }

    .creatable {
        width: 100%;
    }

    .desc-area {
        width: 100%;
    }

    .profile-name {
        font-size: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .form-container {
        width: 100%;
    }

    .form-input {
        width: 100%;
    }

    .desc-area {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {

    .profile-name {
        font-size: 16px;
    }

    .data-container-wrapper {
        min-height: 625px;
    }

    .leftpanel-logo img {
        width: 80%;
    }

    /* .HeaderSection {
        margin-bottom: 30px;
    } */

    /* -------Home start-------- */
    .home-title h1 {
        font-size: 25px;
    }

    .pricing-title h1 {
        font-size: 25px;
    }

    .navbar-blue {
        padding: 5px 20px;
    }

    .header-modal {
        padding: 5px 20px;
    }

    /* -------Home end-------- */
}

/* resp */
@media (min-width:992px) {
    .sidebar-nav {
        transform: none !important;
        visibility: visible !important;

    }

    .toggler-left-panel {
        display: none;
    }

    .right-panel-container {
        margin-left: var(--offcanvas-width);
    }

    .header-wrap {
        margin-left: 260px;
    }
}